import React, { useState } from 'react';
import styled from 'styled-components';
import { Image } from 'cloudinary-react';



const TabsHeader = styled.div`
  display: flex;
  padding-top:15px;
  padding-bottom:15px;
  justify-content: center;
`;

const Tab = styled.div`
  margin: 0 30px;
  cursor: pointer;
  padding: 5px 25px;
  color: ${props => (props.active ? 'white' : '#888')};
  border-bottom: ${props => (props.active ? '2px solid red' : 'none')};
  transition: 0.3s;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin: 0 5px; // Reduce margin
    padding: 5px 10px; // Adjust padding
    font-size: 0.7em; // Reduce font size to fit on smaller screens
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: black;
  margin-top: 40px; // You have a fixed navbar, so this might need adjustment
  overflow: auto; // Add this to handle scrolling if the content is larger than the screen
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  // Removed min-height to allow the content to grow as needed
  @media (max-width: 768px) {
    padding: 10px; // Reduce padding on smaller screens
  }
  h1 {
    @media (max-width: 600px) {

    margin: 0; // Remove margin
  }

  }

  div {
    @media (max-width: 600px) {
      font-size: 0.8em; // Reduce padding on smaller screens
    }
  }



`;


const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
    background-color: black;
  color: white;
  height: 60px;
  text-decoration: none;
  font-style: italic;

  border-radius: 5px;
  transition: 0.3s;
  font-size: 1em;

  @media (max-width: 768px) {
    font-size: 1em; // Adjust font size
  }
`;



const DownloadLinkWrapper = styled.div`
  width: 100%;
  bottom:0;
  padding-bottom:20px;
  z-index:2;
`;



const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    width: 100%;
    height: auto;
    object-fit: cover; // Ensures the image covers the allotted space without distorting aspect ratio

    @media (max-width: 768px) { // For mobile devices
      height: 500px; // Set a fixed height for the image
      object-fit: cover; // Ensures the image covers the allotted space without distorting aspect ratio
    }

 
        @media (min-width: 1700px) { // For larger screens
      max-width: 70%; // Restrict the width to 80% of the container
    }
  }
`;



export default function TabsComponent() {
    const [activeTab, setActiveTab] = useState('tapsMixers');

    const tabData = {
        tapsMixers: {
            content: <><h1>TAPS & MIXERS</h1>
                <div>View our comprehensive collection of taps & mixers.
                    We are certain that you will find a style to suit your taste.</div>
            </>,
            imageUrl: "https://res.cloudinary.com/dbwam0pud/image/upload/c_scale,w_1000/v1718380872/TAP_scene_lifestyle_odyo1z.png",
            mobileImageUrl: "https://res.cloudinary.com/dbwam0pud/image/upload/c_scale,w_1000/v1718380957/TAP_scene_lifestyle_odyo1z_416de4.png",
            pdfLink: "Taps & Mixers.pdf"
        },
        shower: {
            content: <><h1>SHOWER</h1>
                <div>View our comprehensive collection of shower heads.
                    We are certain that you will find a style to suit your taste.</div>
            </>,
            mobileImageUrl: "https://res.cloudinary.com/dbwam0pud/image/upload/v1718723028/shower_scene_lifestyle_nfv8ly_855a9f_004890.png",
            imageUrl: "https://res.cloudinary.com/dbwam0pud/image/upload/c_scale,w_1000/v1718379651/shower_scene_lifestyle_nfv8ly.png",
            pdfLink: "Shower.pdf"
        },
        accessories: {
            content: <><h1>ACCESSORIES</h1>
                <div>View our comprehensive collection of bathroom accessories.
                    We are certain that you will find a style to suit your taste.</div>
            </>,
            mobileImageUrl: "https://res.cloudinary.com/dbwam0pud/image/upload/c_scale,w_1000/v1718380642/ACCESSORIES_SCENE_LIFESTYLE_1_aczfxc_b1ed9e.png",
            imageUrl: "https://res.cloudinary.com/dbwam0pud/image/upload/c_scale,w_1000/v1718380459/ACCESSORIES_SCENE_LIFESTYLE_1_aczfxc.png",
            pdfLink: "Bathroom Accessories.pdf"
        },
        plumbing: {
          content: <><h1>GENERAL PLUMBING</h1>
              <div>View our comprehensive collection of general plumbing products.
                  We are certain that you will find a style to suit your taste.</div>
          </>,
           mobileImageUrl: "https://res.cloudinary.com/dbwam0pud/image/upload/c_scale,w_1000/v1718380313/PLUMBING_SCENE_LIFESTYLE_xzy1df_2ed7f7.png",
          imageUrl: "https://res.cloudinary.com/dbwam0pud/image/upload/c_scale,w_1000/v1718379662/PLUMBING_SCENE_LIFESTYLE_xzy1df.png",
          pdfLink: "General Plumbing.pdf"
      },
    }

    const currentData = tabData[activeTab];



    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePdfDownload = (url) => {
      window.open(url, '_blank'); // Open the PDF in a new tab
    };
    
    return (
        <>
            <TabsContainer>
                <TabsHeader>
                    <Tab onClick={() => setActiveTab('tapsMixers')} active={activeTab === 'tapsMixers'}>
                        TAPS & MIXERS
                    </Tab>
                    <Tab onClick={() => setActiveTab('shower')} active={activeTab === 'shower'}>
                        SHOWER
                    </Tab>
                    <Tab onClick={() => setActiveTab('accessories')} active={activeTab === 'accessories'}>
                        ACCESSORIES
                    </Tab>
                    <Tab onClick={() => setActiveTab('plumbing')} active={activeTab === 'plumbing'}>
                        PLUMBING
                    </Tab>
                </TabsHeader>

                <Content>
                    <p>{currentData.content}</p>

                    <ImageWrapper>
                    <img
              src={isMobile ? currentData.mobileImageUrl : currentData.imageUrl}
              alt="Display" // Add appropriate alt text
              style={{
                objectFit: 'cover', // Ensures the image covers the allotted space without distorting aspect ratio
              }}
            />
                    </ImageWrapper>

                    <DownloadLinkWrapper>
                        <DownloadLink onClick={()=>handlePdfDownload(currentData.pdfLink)} download>
                            CLICK HERE TO VIEW / DOWNLOAD
                        </DownloadLink>
                    </DownloadLinkWrapper>
                </Content>
            </TabsContainer>
        </>
    );
}

