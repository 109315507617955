// src/components/HeroSection.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Video } from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ContactUsSection from '../pages/ContactUs';

const Hero = styled.section`
   display: flex;
   align-items: center;  // Vertically center
   justify-content: center;  // Horizontally center
   height: 100vh;
   position: relative;
   text-align: center;
   scroll-snap-align: none;
`;
const DownloadIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 2em; // Adjust the size as needed
  color: white;
  z-index:5;
  position: absolute;
  bottom: 100px; // Adjust as needed
  left: 50%;
  transform: translateX(-50%);
  &:hover {
    color: #ccc; // Change color on hover
  }
`;

const StyledVideo = styled(Video)`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 4;
object-fit: cover; // Ensure the video fits within the container
object-position: center; // Adjust as needed to best display the video
`;
const StyledImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;


const AboutButton = styled.button`
  font-size: 25px;
  padding: 10px 20px;
  border: none;
  margin-top:150px;
  border-radius: 10px;
   z-index: 9;


  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, black, #333333);
  transition: all 0.3s ease;
  
  &:hover {
    background: linear-gradient(to right, #333333, black);
  }
`;

const FocusPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #326788;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  color: white; /* Added white color to text */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
`;



function HeroSection() {
   const [isPanelOpen, setPanelOpen] = useState(false);
   const [videoPublicId, setVideoPublicId] = useState("H_C_Website_Opener_1_upjjf3"); // default video ID
   React.useEffect(() => {
      const isMobile = window.innerWidth <= 768;
      if (isMobile) {
         // Set to mobile optimized video public ID
         setVideoPublicId("H_C_Website_Opener_1_m6qyff-c_crop_h_944_w_889_aozluq_b5hodj"); // replace with your mobile video public ID
      } else {
         // Set to desktop optimized video public ID
         setVideoPublicId("H_C_Website_Opener_1_upjjf3"); // replace with your desktop video public ID
      }
   }, []);
  
   const videoRef = React.useRef(null);

   const handleDownload = () => {
      const videoUrl = " https://res.cloudinary.com/dbwam0pud/video/upload/v1718379919/H_C_Website_Opener_1_m6qyff-c_crop_h_944_w_889_aozluq_b5hodj.mp4"; // Replace with the actual video URL
      window.open(videoUrl, '_blank'); // Open the video in a new tab for viewing/downloading
    };

  const [videoLoading, setVideoLoading] = React.useState(true);


   return (
      <>
         <div style={{ marginTop: 40 }} />
         <Hero id="HeroSection">
    


<StyledVideo 
    cloudName="dbwam0pud"
    publicId={videoPublicId}
    autoPlay 
    muted 
    controls
    poster={`https://res.cloudinary.com/dbwam0pud/image/upload/v1718376332/Handcposter_pdtzhz.png`}
    loop 
    playsInline 
    style={{ width: '100%', height: '100%' }}
    onLoadedData={() => setVideoLoading(false)} 
/>
            <AboutButton onClick={() => setPanelOpen(true)}>FIND A DISTRIBUTOR</AboutButton>
            <DownloadIcon icon={faDownload} onClick={handleDownload} />
            {isPanelOpen && (
               <FocusPanel>
                  <CloseButton onClick={() => setPanelOpen(false)}>X</CloseButton>
                  <ContactUsSection />
               </FocusPanel>
            )}
         </Hero>
      </>
   );
}
export default HeroSection;
