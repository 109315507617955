import React, { Suspense } from 'react';

import '../GlobalStyles.js';
import HeroSection from '../components/HeroSection';
import Navbar from '../components/Navbar';

import TabsComponent from '../components/TabComponent.jsx';
import styled from 'styled-components';


const LifestyleSection = React.lazy(() => import('./LifestyleSection.jsx'));

const Section = styled.div`
  height: 100vh; // Keep the section height to full viewport
  padding-top: 20px; // Push the content down by the height of the navbar
  box-sizing: border-box; // Ensure padding is included in the height calculation
  `;


const ScrollContainer = styled.div`
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-top: 30px; // Add padding on top to prevent content from hiding behind the navbar
  box-sizing: border-box; // Include padding in height calculation
`;


function HomePage() {

  return (
    <>

      <ScrollContainer>
        <Navbar />

        <Section>
          <HeroSection />
        </Section>
        <Section>
          <TabsComponent />
        </Section>
        <Suspense fallback={<div>Loading...</div>}>
          <Section>
            <LifestyleSection />
          </Section>
        </Suspense>

      </ScrollContainer>


    </>
  );
}

export default HomePage;
