
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Sample data
const categories = {
    "Air Series": ["H&C_SPEC SHEETS_AIR.pdf"],
    "Bella Series": ["H&C_SPEC_SHEETS_BELLA.pdf"],
    "Breo Brushed Bronze Series": ["H&C_TECHNICAL SHEETS_BREO_BRUSHED_BRONZE.pdf"],
    "Breo Chrome Series": ["H&C_TECHNICAL SHEETS_BREO_CHROME.pdf"],
    "Breo Chrome 1/4' Turn Series": ["H&C_TECHNICAL SHEETS_BREO_CHROME_1-4'_TURN.pdf"],
    "Divo Series": ["H&C_TECHNICAL SHEETS_DIVO.pdf"],
    "Dura Series": ["H&C_TECHNICAL SHEETS_DURA.pdf"],
    "House Proud Series": ["H&C_TECHNICAL SHEETS_HOUSE_PROUD.pdf"]
};

const Panel = styled.div`
  background-color: #f9f9f9; /* Light grey background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 400px; /* Set a max-width for better layout */
  margin: auto; /* Center align the panel */
`;


const Dropdown = styled.select`
  width: 100%; /* Full-width */
  padding: 10px 15px;
  margin-bottom: 15px; /* Space between dropdowns */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
  background-color: white;
  font-size: 16px; /* Adequate font size for readability */
  color: #333; /* Dark grey color for text */

  &:focus {
    border-color: #007bff; /* Highlight color when focused */
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glowing effect on focus */
  }

  &:disabled {
    background-color: #eee; /* Different background for disabled state */
    color: #999; /* Light grey color for disabled text */
  }
`;

const DownloadButton = styled.button`
  background-color: #007bff; /* Primary button color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;



export default function SelectFocusPanel() {
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedPdf, setSelectedPdf] = useState("");

    const [pdfs, setPdfs] = useState([]);

    useEffect(() => {
        if (selectedCategory) {
            setPdfs(categories[selectedCategory]);
        }
    }, [selectedCategory]);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleDownloadClick = () => {
        if (selectedPdf) {
            // Assuming the PDFs are stored in a public folder in your project
            // Update the path according to your project structure
            const pdfPath = `${selectedPdf}`;
            window.open(pdfPath, '_blank');
        }
    };



    const handlePdfDownload = (e) => {
        setSelectedPdf(e.target.value);
    };

    return (
        <Panel>
            <Dropdown onChange={handleCategoryChange}>
                <option value="">Select a Category</option>
                {Object.keys(categories).map(category => (
                    <option key={category} value={category}>{category}</option>
                ))}
            </Dropdown>
            <Dropdown onChange={handlePdfDownload} disabled={!selectedCategory}>
                <option value="">Select a PDF</option>
                {pdfs.map(pdf => (
                    <option key={pdf} value={pdf}>{pdf}</option>
                ))}
            </Dropdown>

            <DownloadButton onClick={handleDownloadClick} disabled={!selectedPdf}>
                Download PDF
            </DownloadButton>

        </Panel>
    );
}
