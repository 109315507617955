import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../logo.svg';
import logoblack from '../logoblack.svg';
import { useEffect } from 'react';
import { faCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Logo = styled.img`
  height: 40px;
  margin-right: auto;
`;


const CenterText = styled.a`
  flex-grow: 1; // Allow text to grow and fill space
  text-align: end; // Center text
  font-size: 1em;
  white-space: nowrap; // Prevent wrapping
  overflow: hidden; // Hide overflow
  text-overflow: ellipsis; // Show ellipsis if text is too long
  text-decoration: none;
  color: gray;
  opacity: 0.8; // Initial opacity, making it look greyed out

  &:hover {
    opacity: 1; // Full opacity on hover
  }

  @media(max-width: 768px) {
    font-size: 0.7em; // Adjust font size on mobile
  }
`;


const BurgerMenu = styled.div`
  flex-shrink: 0; // Prevent burger menu from shrinking
  width: 25px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 40px; // Push burger menu to the right

  & div {
    width: 100%;
    height: 2px;
    background-color: black;
  }
`;

const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 0 20px;
`;


const VerticalLine = styled.div`
  height: 30px;
  width: 1px;
  background-color: black;
  margin: 0 20px;
`;

export default function CustomNavbar() {
  const [logoSrc, setLogoSrc] = useState(logoblack);
  const [bgColor, setBgColor] = useState('white'); // Default background color

  useEffect(() => {
    const matchDark = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setLogoSrc(e.matches ? logo : logoblack);
      setBgColor(e.matches ? '#333' : 'white'); // Dark background for dark mode, white for light mode
    };

    matchDark.addListener(handleChange);
    handleChange(matchDark);

    return () => matchDark.removeListener(handleChange);
  }, []);


  const handlePdfDownload = () => {
    window.open('H&CCompleteCatalog2024.pdf', '_blank'); // Open the PDF in a new tab
  };
  return (
    <Navbar bgColor={bgColor}>
      <Logo src={logoblack} />

      <CenterText onClick={handlePdfDownload}>DOWNLOAD OUR COMPLETE CATALOGUE 

      <FontAwesomeIcon style={{paddingLeft:10}} icon={faCircleDown} />

      </CenterText>
      <VerticalLine />
      
    </Navbar>
  );
}
