import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm, ValidationError } from '@formspree/react';
import SelectFocusPanel from '../components/SelectFocusPanel';

const SectionContainer = styled.div`
  padding: 60px 10%;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const Input = styled.input`
  width: 100%; // Make the input take full width
  margin: 10px 0; // Margin for top and bottom
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%; // Full width for textarea
  margin: 10px 0; // Margin for top and bottom
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; // Allow vertical resizing
`;

const SubmitButton = styled.button`
  margin: 20px 0; // Adjust margin for top and bottom
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #555; // Slightly darker on hover
  }

  &:focus {
    outline: none;
  }
`;

const Label = styled.label`
  display: block; // Ensure label is on a separate line
  margin-bottom: 5px; // Margin only at the bottom
  text-align: left; // Align text to the left
`;




export default function ContactUsSection({ requestType }) {
  const [state, handleSubmit] = useForm("mgeggjzp");

  if (state.succeeded) {
    return <p>We will be in touch!</p>;
  }
  const formTitle = requestType ? `Request ${requestType}` : "Contact Us";


  return (
    <SectionContainer>
      {requestType === "sheets" ? (<>

        <h2>Technical CAD Sheets</h2>

        <SelectFocusPanel />
      </>) : (<>
        <h2>{formTitle}</h2>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="firstName">First Name</Label>
          <Input id="firstName" type="text" name="firstName" />
          <ValidationError prefix="First Name" field="firstName" errors={state.errors} />

          <Label htmlFor="lastName">Last Name</Label>
          <Input id="lastName" type="text" name="lastName" />
          <ValidationError prefix="Last Name" field="lastName" errors={state.errors} />

          <Label htmlFor="phone">Phone</Label>
          <Input id="phone" type="text" name="phone" />
          <ValidationError prefix="Phone" field="phone" errors={state.errors} />

          <Label htmlFor="email">Email Address</Label>
          <Input id="email" type="email" name="email" />
          <ValidationError prefix="Email" field="email" errors={state.errors} />

          <Label htmlFor="company">Company</Label>
          <Input id="company" type="text" name="company" />
          <ValidationError prefix="Company" field="company" errors={state.errors} />

          <Label htmlFor="message">{requestType === 'CAD Files' ? "Specify products you need CAD Files for " : "Message"}</Label>
          <TextArea id="message" name="message" />
          <ValidationError prefix="Message" field="message" errors={state.errors} />

          <SubmitButton type="submit" disabled={state.submitting}>
            Submit
          </SubmitButton>
        </Form>
      </>
      )
      }

    </SectionContainer>
  );
};
