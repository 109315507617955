import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';

export default function App() {

    React.useEffect(() => {
        const loadFont = () => {
          const link = document.createElement('link');
          link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
          link.rel = 'stylesheet';
          document.head.appendChild(link);
        };
    
        window.addEventListener('load', loadFont);
    
        // Cleanup function
        return () => window.removeEventListener('load', loadFont);
      }, []);
    
    return (
        <Routes>
         <Route path="/" element={<HomePage />} />
        </Routes>
)
}
